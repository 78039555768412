<template>
  <div class="px-6">
    <div class="pt-5 pb-2 px-2">
      <div class="d-flex w-100 justify-space-between">
        <span>
          <p class="gray--text mr-4 mt-1">
            Experience Categories ({{ total }})
          </p>
        </span>
        <base-input
          placeholder="Search Categories"
          @input="searchCategories"
          :rules="[]"
          clearable
        ></base-input>
      </div>
      <div class="mt-4">
        <div
          class="py-2 text-center radius-5 secondary cursor-pointer"
          @click="createHandler"
        >
          <span class="white--text font-16">Add New Category</span>
        </div>
      </div>
    </div>

    <div class="categories-table">
      <!-- table -->
      <v-data-table
        :server-items-length="total"
        :headers="headers"
        :items="listItems"
        :items-per-page="20"
        hide-default-footer
        mobile-breakpoint="0"
        item-key="_id"
        class="elevation-0"
        @click:row="editHandler"
        :item-class="
          () => {
            return 'cursor-pointer';
          }
        "
      >
        <!-- name & email -->
        <template v-slot:item.name="{ item }">
          <div class="d-flex">
            <div>
              <span class="font-16 white-grey--text">{{ item.name }}</span>
            </div>
          </div>
        </template>

        <!-- last login -->
        <template v-slot:item.lastLogin="{ item }">
          <span class="d-block font-13 white-grey--text">{{
            $moment(item.lastLogin).format("LLLL")
          }}</span>
        </template>

        <!-- create time -->
        <template v-slot:item.created_at="{ item }">
          <span class="d-block font-13 white-grey--text">{{
            $moment(item.created_at).format("LLLL")
          }}</span>
        </template>

        <!-- actions -->
        <template v-slot:item.actions="{ item }">
          <div
            class="icon-cover mr-1 cursor-pointer"
            @click="deleteConfirm(item, $event)"
          >
            <v-icon class="mx-2" color="red">mdi-delete</v-icon>
          </div>
        </template>
      </v-data-table>
    </div>
    <div class="pagination-count text-center">
      <v-pagination
        v-model="page"
        :length="totalFilter"
        circle
        @input="fetchList"
      ></v-pagination>
    </div>
    <!-- add category -->
    <v-dialog v-model="dialog" content-class="white px-6 py-7" max-width="500">
      <category-dialog
        :key="dialog"
        @close="dialog = false"
        @added="addedHandler"
        :item="item"
      ></category-dialog>
    </v-dialog>

    <!-- delete category -->
    <v-dialog v-model="deleteDialog" width="300">
      <delete-confirm
        :key="deleteDialog"
        @confirmAccess="deleteHandler"
        @closeDialog="deleteDialog = false"
      ></delete-confirm>
    </v-dialog>
  </div>
</template>

<script>
import CategoryDialog from "@/components/dialogs/Category";
export default {
  components: {
    CategoryDialog
  },
  data: () => ({
    avatar:
      "https://www.ekoparkotomasyon.com/wp-content/public_html/cart/image/data/uploads/2013/12/default-placeholder.png",
    headers: [
      { text: "Name", value: "name" },
      { text: "Actions", value: "actions", width: "50px", sortable: false }
    ],
    listItems: [],
    pageSize: 30,
    page: 1,
    total: 0,
    dialog: false,
    deleteDialog: false,
    item: "",
    searchWord: ""
  }),
  computed: {
    totalFilter() {
      return Math.ceil(+(this.total / 20));
    }
  },
  methods: {
    createHandler() {
      this.item = undefined;
      this.dialog = true;
    },
    deleteConfirm(item, event) {
      this.deleteDialog = true;
      this.item = item;
      event.stopPropagation();
    },
    async deleteHandler() {
      if (this.item) {
        const res = await this.$axios.delete(`/categories/${this.item._id}`);

        if (res && res.data) {
          this.$store.dispatch("showSnack", {
            text: "deleted successfully",
            color: "success"
          });
          this.deleteDialog = false;
          await this.fetchList();
        } else {
          this.$store.dispatch("showSnack", {
            text: res.error,
            color: "error"
          });
        }
      }
    },
    async editHandler(item) {
      this.item = item;
      this.dialog = true;
    },
    addedHandler() {
      this.dialog = false;
      this.fetchList();
    },
    async searchCategories(searchWord) {
      this.listItems = [];
      this.page = 1;
      this.searchWord = searchWord;
      await this.fetchList();
    },
    async fetchList() {
      let { data } = await this.$axios.get(
        `categories/all/new?${
          (this.searchWord && this.searchWord.length) > 0
            ? "searchWord=" + this.searchWord + "&"
            : ""
        }&pageSize=${this.pageSize}&pageNumber=${this.page}&sort=0`
      );
      if (data) {
        this.listItems = data.data;
        this.total = data.count;
      }
    }
  },
  async created() {
    await this.fetchList();
  }
};
</script>

<style lang="scss" scoped>
.header {
  overflow: hidden;
  height: 80px;
}
.categories-table {
  overflow-y: auto;
  height: calc(100vh - 80px - 100px);

  .mdi-arrow-up {
    display: none;
  }
}
</style>
