<template>
  <div>
    <div class="d-flex align-center justify-space-between b-bottom mb-6">
      <span class="d-block font-16 font-500">New Experience Category</span>
      <base-select
        v-model="language"
        :items="$root.languages"
        placeholder="Language"
        :returnObject="false"
      ></base-select>
      <v-icon @click="close" size="20" color="primary">mdi-close</v-icon>
    </div>
    <v-form v-model="valid" @submit.prevent="submitHandler">
      <v-row>
        <!-- first name -->
        <v-col class="py-1" cols="12" md="6">
          <base-input placeholder="Name" v-model="obj.name[language]" />
        </v-col>

        <v-col class="d-flex" cols="12">
          <!-- buttons -->
          <buttons-experience
            @cancel="close"
            @next="submitHandler"
            text="Submit"
            :isValid="valid"
            :key="valid"
          ></buttons-experience>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import ButtonsExperience from "@/components/Buttons/ButtonsExperience.vue";
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          name: {}
        };
      }
    }
  },
  components: {
    ButtonsExperience
  },
  data: () => ({
    obj: { name: {} },
    valid: false,
    language: "en"
  }),
  methods: {
    async fetchItemByID() {
      if (this.item._id) {
        let { data } = await this.$axios.get(`categories/${this.item._id}`);
        this.obj = { ...data.data };
      }
    },
    async submitHandler() {
      // request to create host id
      let formData = new FormData();
      formData.append("name", JSON.stringify(this.obj.name));
      formData.append("country", this.$root.country.short);
      let data;
      if (this.obj && this.obj._id) {
        data = await this.$axios.put(`/categories/${this.obj._id}`, formData);
      } else {
        data = await this.$axios.post(`/categories`, formData);
      }

      if (data && data.data) {
        this.$store.dispatch("showSnack", {
          text:
            this.obj && this.obj._id
              ? "Category Edited Successfully"
              : "Category Created Successfully",
          color: "success"
        });
        this.$emit("added");
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error"
        });
      }
    },
    close() {
      this.$emit("close");
    }
  },
  async created() {
    this.language = this.$root.language;
    await this.fetchItemByID();
  }
};
</script>
